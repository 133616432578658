<template>
	<div>
		<div v-if="loading">
			<div class="d-flex flex-column justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
				<p class="text-center mt-2"><strong>Loading...</strong></p>
			</div>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Company Information</h4>
						</div>
						<div v-if="partnerData" class="card-body px-3 py-2">
							<div class="list-group list-group-flush mt-2 mb-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>COMPANY NAME</span
												>
										</div>
										<div class="col-auto">
											<span v-if="partnerData?.owner" class="">{{
												partnerData.company_name
											}}</span>
											<span v-else class="">N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>COMPANY PHONE NUMBER</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{ partnerData.company_phone }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>COMPANY EMAIL ADDRESS</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{ partnerData.company_email }}</span>
										</div>
									</div>
								</div>

								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>COMPANY REGISTRATION NUMBER</span
												>
										</div>
										<div class="col-auto">
											<span v-if="partnerData.rc_number" class=""
												>RC {{ partnerData.rc_number }}</span
												>
											<span v-else class="">N/A</span>
										</div>
									</div>
								</div>

								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>COMPANY ADDRESS</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												partnerData?.company_address
													? partnerData?.company_address
													: 'N/A'
											}}</span>
										</div>
									</div>
								</div>

								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>YEARS IN BUSINESS</span
												>
										</div>
										<div class="col-auto">
											{{
												partnerData?.years_of_experience
													? partnerData?.years_of_experience
													: 'N/A'
											}}
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"> Status</span>
										</div>
										<div class="col-auto">
											<span
												:class="
													partnerData?.status === 'active'
														? 'text-success'
														: 'text-danger'
												"
												>
												{{
													partnerData?.status === 'active'
														? 'Active'
														: 'Inactive'
												}}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <feature-flag-enabled feature-flag="REVENUE_AND_COST_AUTOMATION"> -->
				<div class="col-12 col-xl-4">
					<!-- <partner-earnings :total-earnings="partnerWallet"> </partner-earnings> -->

					<div class="card">
						<div class="card-body" v-if="corporateDocuments.length">
							<div
								v-for="(document, index) in corporateDocuments"
								:key="index"
								class="list-group list-group-flush"
								>
								<div class="list-group-item pt-2 pb-3 px-0">
									<div
										class="d-flex justify-content-between align-items-center"
										>
										<div
											class="d-flex justify-content-between align-items-center"
											>
											<img src="@/assets/img/pdf_download.svg" />
											<div class="col d-flex flex-column">
												<span class="">{{
													document.document_type
														? document.document_type
														: 'No document available'
												}}</span>
												<small
													:class="[
														document.status === 'submitted'
															? 'text-warning'
															: document.status === 'failed'
																? 'text-danger'
																: document.status === 'completed'
																	? 'text-success'
																	: '',
													]"
													>{{
														document.status
															? document.status
															: 'No status available'
													}}</small
													>
											</div>
										</div>
										<LightGallery
											v-if="showDocument"
											:images="images"
											:index="index"
											:disable-scroll="true"
											@close="handleClose"
											/>
										<div v-if="corporateDocuments.length" class="">
											<b-dropdown
												size="sm"
												variant="link"
												right
												text=""
												class="position-relative text-center w-100 m-md-2"
												:toggle-class="'text-decoration-none'"
												:no-caret="true"
												>
												<template #button-content>
													<div class="w-100 text-center">
														<i class="fe fe-more-vertical"></i>
													</div>
												</template>
												<div
													v-for="(
														eachDocument, documentIndex
													) in formattedDocumentsArray"
													:key="documentIndex"
													@click="index = documentIndex"
													>
													<b-dropdown-item
														class="text-sm pl-0 ml-0"
														@click="viewDetails"
														>
														<small> View document</small>
													</b-dropdown-item>
												</div>

												<b-dropdown-item
													link-class="text-danger"
													@click="
														openUpdateModal(
															'companyDocumentUpdateModal',
															document
														)
													"
													>
													Update
												</b-dropdown-item>
											</b-dropdown>
											<b-modal
												centered
												hide-footer
												id="companyDocumentUpdateModal"
												>
												<template #modal-title>
													<div class="d-flex flex-column">
														<h3 class="mb-3">Update status</h3>
														<small class="text-muted"
															>Kindly select the appropraite status</small
															>
													</div>
												</template>
												<div class="">
													<small class="text-muted">status</small>
													<v-select
														v-model="documentStatus"
														class="form-group"
														:options="DocumentStatusList"
														label="label"
														:reduce="(eachStatus) => eachStatus?.status"
														placeholder="Select Status"
														>
													</v-select>
												</div>
												<template>
													<div
														class="d-flex justify-content-end align-items-end"
														>
														<b-button
															@click="
																$bvModal.hide('companyDocumentUpdateModal')
															"
															class=""
															variant="secondary"
															>Cancel</b-button
															>
														<b-button
															class="ml-3"
															@click="handleDocumentUpdate()"
															variant="primary"
															>Proceed</b-button
															>
													</div>
												</template>
											</b-modal>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="d-flex align-items-center p-3">
							<img src="@/assets/img/pdf_download.svg" />
							<p class="pl-1 mt-3 text-sm text-muted">
								No document has been uploaded
							</p>
						</div>
					</div>
				</div>
				<!-- </feature-flag-enabled> -->
			</div>

			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header">
							<div
								class="
                  row
                  align-items-center
                  justify-content-start
                  font-width-bold
                "
								>
								Stakeholders Information
							</div>
						</div>

						<b-table
							striped
							hover
							selectable
							responsive
							show-empty
							:items="tableData"
							:fields="fields"
							:current-page="currentPage"
							:busy="loading"
							>
							<template #table-busy>
								<div
									class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
									>
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>

							<template #empty>
								<p class="text-center text-secondary">No records available</p>
							</template>
							<template #cell(stakeholder_name)="data">
								<span>{{ data?.item?.profile?.fname }} {{ data?.item?.profile?.lname }}</span>
							</template>

							<template #cell(stakeholder_share)="data">
								<span> {{ data?.item?.profile?.share_amount }}%</span>
							</template>

							<template #cell(document)="data">
								<span>
									{{ data.item.document ? data.item.document : 'N/A' }}</span
									>
							</template>

							<template #cell(document_number)="data">
								<div class="d-flex flex-column">
									<span>
										{{
											data.item.document_number
												? data.item.document_number
												: 'N/A'
										}}</span
										>
									<span v-if="data.item.document_number" class="text-success"
										><img
											src="@/assets/img/verifiedTick.svg"
											class="mr-2"
											/>Verified</span
										>
									<span v-else class="text-danger"
										><img src="@/assets/img/unverifiedTick.svg" />Not
										verified</span
										>
								</div>
							</template>

							<template #cell(dob)="data">
								<span>{{ data.item.dob ? data.item.dob : 'N/A' }}</span>
							</template>

							<template #cell(address)="data">
								<div class="d-flex flex-column">
									<span>{{
										data.item.address ? data.item.address : 'N/A'
									}}</span>
									<span v-if="data.item.address" class="text-success"
										><img
											src="@/assets/img/verifiedTick.svg"
											class="mr-2"
											/>Verified</span
										>
									<span v-else class="text-danger"
										><img src="@/assets/img/unverifiedTick.svg" />Not
										verified</span
										>
								</div>
							</template>

							<template #cell(bill_upload)="data">
								<span
									v-if="bill_upload"
									class="underline text-success text-decoration-underline"
									@click="handleDocument(data.item)"
									>
									view document
								</span>
								<span v-else>No document uploaded</span>
							</template>
						</b-table>

						<div class="card-footer">
							<div class="row align-items-center">
								<div class="col">
									<!-- <span class="text-dark font-weight-bold">{{
                    tablePageText
                  }}</span> -->
								</div>
								<!-- <div class="col-auto">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecords"
                    :per-page="pageSize"
                  ></b-pagination>
                </div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import FeatureFlagEnabled from '@/components/layout/FeatureFlagEnabled'
import { extractErrorMessage } from '@/utils/helpers'
import Swal from 'sweetalert2'
export default {
  name: 'PartnerProfile',
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    },
    accountSid: {
      type: [Number, String],
      required: true
    }
  },
  // computed :{
  //    totalRecords(){
  //     return this.tableData.length
  //    }
  // },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    },
    formattedDocumentsArray () {
      const result = [...new Set(this.images)]
      return result
    }
  },
  data () {
    return {
      loading: false,
      index: null,
      errorLoading: false,
      showDocument: false,
      loadingCorporateDocuments: true,
      errorLoadingCorporateDocuments: false,
      loadingShareholderData: true,
      errorLoadingShareholderData: false,
      partnerData: null,
      partnerWallet: null,
      driversCount: null,
      corporateDocuments: [],
      selectedDocument: '',
      documentStatus: '',
      formattedUrl: '',
      images: [],
      DocumentStatusList: [
        {
          label: 'Completed',
          status: 'completed'
        },
        {
          label: 'Failed',
          status: 'failed'
        }
      ],
      currentPage: 1,
      tableData: [],
      fields: [
        {
          key: 'stakeholder_name',
          label: 'Stakeholder name'
        },
        {
          key: 'stakeholder_share',
          label: 'Stakeholder share'
        },
        {
          key: 'document',
          label: 'Document'
        },
        {
          key: 'document_number',
          label: 'Document number'
        },
        {
          key: 'dob',
          label: 'Date of birth'
        },
        {
          key: 'address',
          label: 'Address'
        },
        {
          key: 'bill_upload',
          label: 'Bill upload'
        }
      ]
    }
  },
  created () {
    this.init()
    this.fetchCorporateDocuments()
  },

  methods: {
    async init () {
      this.loading = true
      this.errorLoading = false

      this.fetchPartner()
        .then(() => {
          this.fetchShareholderData()
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleClose () {
      this.index = null
      this.showDocument = false
    },

    fetchPartner () {
      return this.axios
        .get(`/v1/partners/${this.partnerId}`)
        .then((res) => {
          this.partnerData = res.data
          return this.axios.get(
            `/v1/partner/${this.partnerId}/vehicle_drivers?metadata=true&limit=0&page=1`
          )
        })
        .then((res) => {
          this.driversCount = res.data.metadata.total || 0
        })
    },

    fetchCorporateDocuments () {
      this.loadingCorporateDocuments = true
      this.errorLoadingCorporateDocuments = false
      this.axios
        .get(`/v1/partners/${this.accountSid}/corporate-documents`)
        .then((res) => {
          this.corporateDocuments = res.data.data || []
          this.corporateDocuments.map((eachCorporateDocuments) => {
            const formatted = eachCorporateDocuments.document_files
              .split?.('["')[1]
              .split?.('"]')[0]
            this.images.push(formatted)
          })
        })
        .catch(() => {
          this.errorLoadingCorporateDocuments = true
        })
        .finally(() => {
          this.loadingCorporateDocuments = false
        })
    },

    fetchShareholderData () {
      this.loadingShareholderData = true
      this.errorLoadingShareholderData = false
      this.axios
        .get(`/v1/partners/${this.accountSid}/share-holders`)
        .then((res) => {
          this.tableData = res.data.data || []
        })
        .catch(() => {
          this.errorLoadingShareholderData = true
        })
        .finally(() => {
          this.loadingShareholderData = false
        })
    },

    viewDetails () {
      // this.formattedUrl = docUrl.split?.('["')[1].split?.('"]')[0]
      // this.images.push(this.formattedUrl)
      this.showDocument = !this.showDocument
    },

    openUpdateModal (modalId, selectedCorporateDocument) {
      this.$bvModal.show(modalId)
      this.selectedDocument = selectedCorporateDocument
    },
    async handleDocumentUpdate () {
      this.$bvModal.hide('companyDocumentUpdateModal')
      await this.axios
        .put(
          `/v1/partners/${this.accountSid}/corporate-documents/${this.selectedDocument?.id}/status/${this.documentStatus}`,
          {
            user_id: this.currentUser.id
          }
        )
        .then(() => {
          Swal.fire(
            'Status updated',
            'You have successfully updated this document status.',
            'success'
          )
          this.fetchCorporateDocuments()
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'An error occurred!',
            text: extractErrorMessage(e, 'Updating document status failed'),
            showCloseButton: true
          })
        })
    },
    handleVerification () {
      Swal.fire({
        title: 'Verify Identity?',
        text: 'Are you sure you want to continue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '	#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Identity verified',
            'You have successfully verified this partners identity.',
            'success'
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group {
  .list-group-item {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
</style>
